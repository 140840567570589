var exports = {};
exports = {
  A: {
    A: {
      K: 0,
      D: 0,
      E: 0.0271533,
      F: 0.0678831,
      A: 0,
      B: 0.529489,
      aC: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "aC", "K", "D", "E", "F", "A", "B", "", "", ""],
    E: "IE",
    F: {
      aC: 962323200,
      K: 998870400,
      D: 1161129600,
      E: 1237420800,
      F: 1300060800,
      A: 1346716800,
      B: 1381968000
    }
  },
  B: {
    A: {
      "1": 0.00757,
      "2": 0.011355,
      "3": 0.01514,
      "4": 0.00757,
      "5": 0.00757,
      "6": 0.011355,
      "7": 0.00757,
      "8": 0.01514,
      "9": 0.034065,
      C: 0,
      L: 0,
      M: 0,
      G: 0,
      N: 0,
      O: 0.003785,
      P: 0.041635,
      Q: 0,
      H: 0,
      R: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0.011355,
      c: 0,
      d: 0,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0,
      m: 0,
      n: 0,
      o: 0,
      p: 0,
      q: 0.003785,
      r: 0.00757,
      s: 0.064345,
      t: 0.003785,
      u: 0.00757,
      AB: 0.026495,
      BB: 0.064345,
      CB: 0.16654,
      DB: 2.88417,
      EB: 1.57834,
      I: 0.00757
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "C", "L", "M", "G", "N", "O", "P", "Q", "H", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "I", "", "", ""],
    E: "Edge",
    F: {
      "1": 1680825600,
      "2": 1683158400,
      "3": 1685664000,
      "4": 1689897600,
      "5": 1692576000,
      "6": 1694649600,
      "7": 1697155200,
      "8": 1698969600,
      "9": 1701993600,
      C: 1438128000,
      L: 1447286400,
      M: 1470096000,
      G: 1491868800,
      N: 1508198400,
      O: 1525046400,
      P: 1542067200,
      Q: 1579046400,
      H: 1581033600,
      R: 1586736000,
      S: 1590019200,
      T: 1594857600,
      U: 1598486400,
      V: 1602201600,
      W: 1605830400,
      X: 1611360000,
      Y: 1614816000,
      Z: 1618358400,
      a: 1622073600,
      b: 1626912000,
      c: 1630627200,
      d: 1632441600,
      e: 1634774400,
      f: 1637539200,
      g: 1641427200,
      h: 1643932800,
      i: 1646265600,
      j: 1649635200,
      k: 1651190400,
      l: 1653955200,
      m: 1655942400,
      n: 1659657600,
      o: 1661990400,
      p: 1664755200,
      q: 1666915200,
      r: 1670198400,
      s: 1673481600,
      t: 1675900800,
      u: 1678665600,
      AB: 1706227200,
      BB: 1708732800,
      CB: 1711152000,
      DB: 1713398400,
      EB: 1715990400,
      I: 1718841600
    },
    D: {
      C: "ms",
      L: "ms",
      M: "ms",
      G: "ms",
      N: "ms",
      O: "ms",
      P: "ms"
    }
  },
  C: {
    A: {
      "0": 0,
      "1": 0,
      "2": 0.011355,
      "3": 0,
      "4": 0.397425,
      "5": 0,
      "6": 0.00757,
      "7": 0.079485,
      "8": 0,
      "9": 0.00757,
      bC: 0,
      DC: 0,
      J: 0.003785,
      FB: 0,
      K: 0,
      D: 0,
      E: 0,
      F: 0,
      A: 0,
      B: 0.018925,
      C: 0,
      L: 0,
      M: 0,
      G: 0,
      N: 0,
      O: 0,
      P: 0,
      GB: 0,
      v: 0,
      w: 0,
      x: 0,
      y: 0,
      z: 0,
      HB: 0,
      IB: 0,
      JB: 0,
      KB: 0,
      LB: 0,
      MB: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0.00757,
      ZB: 0.00757,
      aB: 0.00757,
      bB: 0,
      cB: 0,
      dB: 0,
      eB: 0,
      fB: 0.00757,
      gB: 0,
      hB: 0.05299,
      iB: 0.003785,
      jB: 0.003785,
      kB: 0,
      lB: 0.02271,
      mB: 0,
      nB: 0,
      EC: 0.003785,
      oB: 0,
      FC: 0,
      pB: 0,
      qB: 0,
      rB: 0,
      sB: 0,
      tB: 0,
      uB: 0,
      vB: 0,
      wB: 0,
      xB: 0,
      yB: 0,
      zB: 0,
      "0B": 0,
      "1B": 0,
      "2B": 0,
      "3B": 0,
      "4B": 0,
      "5B": 0.01514,
      Q: 0,
      H: 0,
      R: 0,
      GC: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0.011355,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0,
      c: 0,
      d: 0.003785,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0.011355,
      m: 0.011355,
      n: 0,
      o: 0,
      p: 0,
      q: 0,
      r: 0.003785,
      s: 0.00757,
      t: 0,
      u: 0,
      AB: 0.00757,
      BB: 0.011355,
      CB: 0.01514,
      DB: 0.06813,
      EB: 0.844055,
      I: 0.738075,
      "6B": 0.003785,
      HC: 0,
      IC: 0,
      cC: 0,
      dC: 0,
      eC: 0,
      fC: 0
    },
    B: "moz",
    C: ["bC", "DC", "eC", "fC", "J", "FB", "K", "D", "E", "F", "A", "B", "C", "L", "M", "G", "N", "O", "P", "GB", "v", "w", "x", "y", "z", "0", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "EC", "oB", "FC", "pB", "qB", "rB", "sB", "tB", "uB", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "Q", "H", "R", "GC", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "I", "6B", "HC", "IC", "cC", "dC"],
    E: "Firefox",
    F: {
      "0": 1379376000,
      "1": 1681171200,
      "2": 1683590400,
      "3": 1686009600,
      "4": 1688428800,
      "5": 1690848000,
      "6": 1693267200,
      "7": 1695686400,
      "8": 1698105600,
      "9": 1700524800,
      bC: 1161648000,
      DC: 1213660800,
      eC: 1246320000,
      fC: 1264032000,
      J: 1300752000,
      FB: 1308614400,
      K: 1313452800,
      D: 1317081600,
      E: 1317081600,
      F: 1320710400,
      A: 1324339200,
      B: 1327968000,
      C: 1331596800,
      L: 1335225600,
      M: 1338854400,
      G: 1342483200,
      N: 1346112000,
      O: 1349740800,
      P: 1353628800,
      GB: 1357603200,
      v: 1361232000,
      w: 1364860800,
      x: 1368489600,
      y: 1372118400,
      z: 1375747200,
      HB: 1386633600,
      IB: 1391472000,
      JB: 1395100800,
      KB: 1398729600,
      LB: 1402358400,
      MB: 1405987200,
      NB: 1409616000,
      OB: 1413244800,
      PB: 1417392000,
      QB: 1421107200,
      RB: 1424736000,
      SB: 1428278400,
      TB: 1431475200,
      UB: 1435881600,
      VB: 1439251200,
      WB: 1442880000,
      XB: 1446508800,
      YB: 1450137600,
      ZB: 1453852800,
      aB: 1457395200,
      bB: 1461628800,
      cB: 1465257600,
      dB: 1470096000,
      eB: 1474329600,
      fB: 1479168000,
      gB: 1485216000,
      hB: 1488844800,
      iB: 1492560000,
      jB: 1497312000,
      kB: 1502150400,
      lB: 1506556800,
      mB: 1510617600,
      nB: 1516665600,
      EC: 1520985600,
      oB: 1525824000,
      FC: 1529971200,
      pB: 1536105600,
      qB: 1540252800,
      rB: 1544486400,
      sB: 1548720000,
      tB: 1552953600,
      uB: 1558396800,
      vB: 1562630400,
      wB: 1567468800,
      xB: 1571788800,
      yB: 1575331200,
      zB: 1578355200,
      "0B": 1581379200,
      "1B": 1583798400,
      "2B": 1586304000,
      "3B": 1588636800,
      "4B": 1591056000,
      "5B": 1593475200,
      Q: 1595894400,
      H: 1598313600,
      R: 1600732800,
      GC: 1603152000,
      S: 1605571200,
      T: 1607990400,
      U: 1611619200,
      V: 1614038400,
      W: 1616457600,
      X: 1618790400,
      Y: 1622505600,
      Z: 1626134400,
      a: 1628553600,
      b: 1630972800,
      c: 1633392000,
      d: 1635811200,
      e: 1638835200,
      f: 1641859200,
      g: 1644364800,
      h: 1646697600,
      i: 1649116800,
      j: 1651536000,
      k: 1653955200,
      l: 1656374400,
      m: 1658793600,
      n: 1661212800,
      o: 1663632000,
      p: 1666051200,
      q: 1668470400,
      r: 1670889600,
      s: 1673913600,
      t: 1676332800,
      u: 1678752000,
      AB: 1702944000,
      BB: 1705968000,
      CB: 1708387200,
      DB: 1710806400,
      EB: 1713225600,
      I: 1715644800,
      "6B": 1718064000,
      HC: 1720483200,
      IC: null,
      cC: null,
      dC: null
    }
  },
  D: {
    A: {
      "0": 0,
      "1": 0.041635,
      "2": 0.09841,
      "3": 0.109765,
      "4": 0.04542,
      "5": 0.230885,
      "6": 0.102195,
      "7": 0.08327,
      "8": 0.09084,
      "9": 0.185465,
      J: 0,
      FB: 0,
      K: 0,
      D: 0,
      E: 0,
      F: 0,
      A: 0,
      B: 0,
      C: 0,
      L: 0,
      M: 0,
      G: 0,
      N: 0,
      O: 0,
      P: 0,
      GB: 0,
      v: 0,
      w: 0,
      x: 0,
      y: 0,
      z: 0,
      HB: 0,
      IB: 0,
      JB: 0,
      KB: 0,
      LB: 0,
      MB: 0,
      NB: 0,
      OB: 0,
      PB: 0.00757,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0.01514,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0,
      ZB: 0,
      aB: 0.003785,
      bB: 0,
      cB: 0.003785,
      dB: 0.02271,
      eB: 0.026495,
      fB: 0.011355,
      gB: 0,
      hB: 0.003785,
      iB: 0.003785,
      jB: 0,
      kB: 0,
      lB: 0.011355,
      mB: 0,
      nB: 0.003785,
      EC: 0,
      oB: 0,
      FC: 0.003785,
      pB: 0,
      qB: 0.003785,
      rB: 0,
      sB: 0,
      tB: 0.02271,
      uB: 0.00757,
      vB: 0,
      wB: 0.03028,
      xB: 0.064345,
      yB: 0.003785,
      zB: 0.003785,
      "0B": 0.011355,
      "1B": 0.00757,
      "2B": 0.00757,
      "3B": 0.00757,
      "4B": 0.00757,
      "5B": 0.01514,
      Q: 0.12112,
      H: 0.011355,
      R: 0.02271,
      S: 0.041635,
      T: 0.00757,
      U: 0.011355,
      V: 0.049205,
      W: 0.06813,
      X: 0.01514,
      Y: 0.011355,
      Z: 0.011355,
      a: 0.03785,
      b: 0.018925,
      c: 0.03028,
      d: 0.041635,
      e: 0.011355,
      f: 0.011355,
      g: 0.01514,
      h: 0.071915,
      i: 0.034065,
      j: 0.04542,
      k: 0.06813,
      l: 0.049205,
      m: 0.170325,
      n: 0.094625,
      o: 0.03028,
      p: 0.03785,
      q: 0.03028,
      r: 0.04542,
      s: 1.49507,
      t: 0.026495,
      u: 0.03785,
      AB: 0.389855,
      BB: 0.29523,
      CB: 1.11279,
      DB: 12.6116,
      EB: 4.62527,
      I: 0.018925,
      "6B": 0.00757,
      HC: 0,
      IC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "J", "FB", "K", "D", "E", "F", "A", "B", "C", "L", "M", "G", "N", "O", "P", "GB", "v", "w", "x", "y", "z", "0", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "EC", "oB", "FC", "pB", "qB", "rB", "sB", "tB", "uB", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "Q", "H", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "I", "6B", "HC", "IC"],
    E: "Chrome",
    F: {
      "0": 1357862400,
      "1": 1680566400,
      "2": 1682985600,
      "3": 1685404800,
      "4": 1689724800,
      "5": 1692057600,
      "6": 1694476800,
      "7": 1696896000,
      "8": 1698710400,
      "9": 1701993600,
      J: 1264377600,
      FB: 1274745600,
      K: 1283385600,
      D: 1287619200,
      E: 1291248000,
      F: 1296777600,
      A: 1299542400,
      B: 1303862400,
      C: 1307404800,
      L: 1312243200,
      M: 1316131200,
      G: 1316131200,
      N: 1319500800,
      O: 1323734400,
      P: 1328659200,
      GB: 1332892800,
      v: 1337040000,
      w: 1340668800,
      x: 1343692800,
      y: 1348531200,
      z: 1352246400,
      HB: 1361404800,
      IB: 1364428800,
      JB: 1369094400,
      KB: 1374105600,
      LB: 1376956800,
      MB: 1384214400,
      NB: 1389657600,
      OB: 1392940800,
      PB: 1397001600,
      QB: 1400544000,
      RB: 1405468800,
      SB: 1409011200,
      TB: 1412640000,
      UB: 1416268800,
      VB: 1421798400,
      WB: 1425513600,
      XB: 1429401600,
      YB: 1432080000,
      ZB: 1437523200,
      aB: 1441152000,
      bB: 1444780800,
      cB: 1449014400,
      dB: 1453248000,
      eB: 1456963200,
      fB: 1460592000,
      gB: 1464134400,
      hB: 1469059200,
      iB: 1472601600,
      jB: 1476230400,
      kB: 1480550400,
      lB: 1485302400,
      mB: 1489017600,
      nB: 1492560000,
      EC: 1496707200,
      oB: 1500940800,
      FC: 1504569600,
      pB: 1508198400,
      qB: 1512518400,
      rB: 1516752000,
      sB: 1520294400,
      tB: 1523923200,
      uB: 1527552000,
      vB: 1532390400,
      wB: 1536019200,
      xB: 1539648000,
      yB: 1543968000,
      zB: 1548720000,
      "0B": 1552348800,
      "1B": 1555977600,
      "2B": 1559606400,
      "3B": 1564444800,
      "4B": 1568073600,
      "5B": 1571702400,
      Q: 1575936000,
      H: 1580860800,
      R: 1586304000,
      S: 1589846400,
      T: 1594684800,
      U: 1598313600,
      V: 1601942400,
      W: 1605571200,
      X: 1611014400,
      Y: 1614556800,
      Z: 1618272000,
      a: 1621987200,
      b: 1626739200,
      c: 1630368000,
      d: 1632268800,
      e: 1634601600,
      f: 1637020800,
      g: 1641340800,
      h: 1643673600,
      i: 1646092800,
      j: 1648512000,
      k: 1650931200,
      l: 1653350400,
      m: 1655769600,
      n: 1659398400,
      o: 1661817600,
      p: 1664236800,
      q: 1666656000,
      r: 1669680000,
      s: 1673308800,
      t: 1675728000,
      u: 1678147200,
      AB: 1705968000,
      BB: 1708387200,
      CB: 1710806400,
      DB: 1713225600,
      EB: 1715644800,
      I: 1718064000,
      "6B": null,
      HC: null,
      IC: null
    }
  },
  E: {
    A: {
      J: 0,
      FB: 0,
      K: 0,
      D: 0,
      E: 0.01514,
      F: 0.003785,
      A: 0,
      B: 0,
      C: 0,
      L: 0.00757,
      M: 0.034065,
      G: 0.00757,
      gC: 0,
      JC: 0,
      hC: 0,
      iC: 0,
      jC: 0,
      kC: 0,
      KC: 0,
      "7B": 0.00757,
      "8B": 0.01514,
      lC: 0.064345,
      mC: 0.09084,
      nC: 0.034065,
      LC: 0.011355,
      MC: 0.026495,
      "9B": 0.034065,
      oC: 0.246025,
      AC: 0.03028,
      NC: 0.049205,
      OC: 0.03785,
      PC: 0.09841,
      QC: 0.03028,
      RC: 0.06056,
      pC: 0.34065,
      BC: 0.03785,
      SC: 0.06813,
      TC: 0.08327,
      UC: 0.09841,
      VC: 1.5405,
      WC: 0.185465,
      XC: 0,
      CC: 0,
      qC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "gC", "JC", "J", "FB", "hC", "K", "iC", "D", "jC", "E", "F", "kC", "A", "KC", "B", "7B", "C", "8B", "L", "lC", "M", "mC", "G", "nC", "LC", "MC", "9B", "oC", "AC", "NC", "OC", "PC", "QC", "RC", "pC", "BC", "SC", "TC", "UC", "VC", "WC", "XC", "CC", "qC"],
    E: "Safari",
    F: {
      gC: 1205798400,
      JC: 1226534400,
      J: 1244419200,
      FB: 1275868800,
      hC: 1311120000,
      K: 1343174400,
      iC: 1382400000,
      D: 1382400000,
      jC: 1410998400,
      E: 1413417600,
      F: 1443657600,
      kC: 1458518400,
      A: 1474329600,
      KC: 1490572800,
      B: 1505779200,
      "7B": 1522281600,
      C: 1537142400,
      "8B": 1553472000,
      L: 1568851200,
      lC: 1585008000,
      M: 1600214400,
      mC: 1619395200,
      G: 1632096000,
      nC: 1635292800,
      LC: 1639353600,
      MC: 1647216000,
      "9B": 1652745600,
      oC: 1658275200,
      AC: 1662940800,
      NC: 1666569600,
      OC: 1670889600,
      PC: 1674432000,
      QC: 1679875200,
      RC: 1684368000,
      pC: 1690156800,
      BC: 1695686400,
      SC: 1698192000,
      TC: 1702252800,
      UC: 1705881600,
      VC: 1709596800,
      WC: 1715558400,
      XC: null,
      CC: null,
      qC: null
    }
  },
  F: {
    A: {
      "0": 0,
      F: 0,
      B: 0,
      C: 0,
      G: 0,
      N: 0,
      O: 0,
      P: 0,
      GB: 0,
      v: 0,
      w: 0,
      x: 0,
      y: 0,
      z: 0,
      HB: 0,
      IB: 0,
      JB: 0,
      KB: 0,
      LB: 0,
      MB: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0,
      ZB: 0,
      aB: 0,
      bB: 0.01514,
      cB: 0,
      dB: 0,
      eB: 0,
      fB: 0,
      gB: 0,
      hB: 0,
      iB: 0,
      jB: 0,
      kB: 0,
      lB: 0,
      mB: 0,
      nB: 0,
      oB: 0,
      pB: 0,
      qB: 0,
      rB: 0,
      sB: 0,
      tB: 0,
      uB: 0,
      vB: 0,
      wB: 0,
      xB: 0,
      yB: 0,
      zB: 0,
      "0B": 0,
      "1B": 0,
      "2B": 0,
      "3B": 0,
      "4B": 0,
      "5B": 0,
      Q: 0,
      H: 0,
      R: 0,
      GC: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0,
      c: 0,
      d: 0,
      e: 0.041635,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0.071915,
      m: 0,
      n: 0,
      o: 0,
      p: 0.00757,
      q: 0.185465,
      r: 0.01514,
      s: 0.738075,
      t: 0.04542,
      u: 0,
      rC: 0,
      sC: 0,
      tC: 0,
      uC: 0,
      "7B": 0,
      YC: 0,
      vC: 0,
      "8B": 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "F", "rC", "sC", "tC", "uC", "B", "7B", "YC", "vC", "C", "8B", "G", "N", "O", "P", "GB", "v", "w", "x", "y", "z", "0", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "rB", "sB", "tB", "uB", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "Q", "H", "R", "GC", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "", "", ""],
    E: "Opera",
    F: {
      "0": 1413331200,
      F: 1150761600,
      rC: 1223424000,
      sC: 1251763200,
      tC: 1267488000,
      uC: 1277942400,
      B: 1292457600,
      "7B": 1302566400,
      YC: 1309219200,
      vC: 1323129600,
      C: 1323129600,
      "8B": 1352073600,
      G: 1372723200,
      N: 1377561600,
      O: 1381104000,
      P: 1386288000,
      GB: 1390867200,
      v: 1393891200,
      w: 1399334400,
      x: 1401753600,
      y: 1405987200,
      z: 1409616000,
      HB: 1417132800,
      IB: 1422316800,
      JB: 1425945600,
      KB: 1430179200,
      LB: 1433808000,
      MB: 1438646400,
      NB: 1442448000,
      OB: 1445904000,
      PB: 1449100800,
      QB: 1454371200,
      RB: 1457308800,
      SB: 1462320000,
      TB: 1465344000,
      UB: 1470096000,
      VB: 1474329600,
      WB: 1477267200,
      XB: 1481587200,
      YB: 1486425600,
      ZB: 1490054400,
      aB: 1494374400,
      bB: 1498003200,
      cB: 1502236800,
      dB: 1506470400,
      eB: 1510099200,
      fB: 1515024000,
      gB: 1517961600,
      hB: 1521676800,
      iB: 1525910400,
      jB: 1530144000,
      kB: 1534982400,
      lB: 1537833600,
      mB: 1543363200,
      nB: 1548201600,
      oB: 1554768000,
      pB: 1561593600,
      qB: 1566259200,
      rB: 1570406400,
      sB: 1573689600,
      tB: 1578441600,
      uB: 1583971200,
      vB: 1587513600,
      wB: 1592956800,
      xB: 1595894400,
      yB: 1600128000,
      zB: 1603238400,
      "0B": 1613520000,
      "1B": 1612224000,
      "2B": 1616544000,
      "3B": 1619568000,
      "4B": 1623715200,
      "5B": 1627948800,
      Q: 1631577600,
      H: 1633392000,
      R: 1635984000,
      GC: 1638403200,
      S: 1642550400,
      T: 1644969600,
      U: 1647993600,
      V: 1650412800,
      W: 1652745600,
      X: 1654646400,
      Y: 1657152000,
      Z: 1660780800,
      a: 1663113600,
      b: 1668816000,
      c: 1668643200,
      d: 1671062400,
      e: 1675209600,
      f: 1677024000,
      g: 1679529600,
      h: 1681948800,
      i: 1684195200,
      j: 1687219200,
      k: 1690329600,
      l: 1692748800,
      m: 1696204800,
      n: 1699920000,
      o: 1699920000,
      p: 1702944000,
      q: 1707264000,
      r: 1710115200,
      s: 1711497600,
      t: 1716336000,
      u: 1719273600
    },
    D: {
      F: "o",
      B: "o",
      C: "o",
      rC: "o",
      sC: "o",
      tC: "o",
      uC: "o",
      "7B": "o",
      YC: "o",
      vC: "o",
      "8B": "o"
    }
  },
  G: {
    A: {
      E: 0,
      JC: 0,
      wC: 0,
      ZC: 0.00289868,
      xC: 0.00289868,
      yC: 0.00724669,
      zC: 0.0115947,
      "0C": 0.00289868,
      "1C": 0.00724669,
      "2C": 0.0333348,
      "3C": 0.00579735,
      "4C": 0.0521762,
      "5C": 0.0768149,
      "6C": 0.0144934,
      "7C": 0.00869603,
      "8C": 0.210154,
      "9C": 0.00434801,
      AD: 0.0217401,
      BD: 0.0101454,
      CD: 0.0463788,
      DD: 0.100004,
      ED: 0.123194,
      FD: 0.0594229,
      LC: 0.0652202,
      MC: 0.0739162,
      "9B": 0.0927576,
      GD: 0.83192,
      AC: 0.189863,
      NC: 0.389872,
      OC: 0.189863,
      PC: 0.329,
      QC: 0.0695682,
      RC: 0.140586,
      HD: 1.11744,
      BC: 0.121744,
      SC: 0.198559,
      TC: 0.207255,
      UC: 0.382625,
      VC: 8.67429,
      WC: 0.61307,
      XC: 0,
      CC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "JC", "wC", "ZC", "xC", "yC", "zC", "E", "0C", "1C", "2C", "3C", "4C", "5C", "6C", "7C", "8C", "9C", "AD", "BD", "CD", "DD", "ED", "FD", "LC", "MC", "9B", "GD", "AC", "NC", "OC", "PC", "QC", "RC", "HD", "BC", "SC", "TC", "UC", "VC", "WC", "XC", "CC", ""],
    E: "Safari on iOS",
    F: {
      JC: 1270252800,
      wC: 1283904000,
      ZC: 1299628800,
      xC: 1331078400,
      yC: 1359331200,
      zC: 1394409600,
      E: 1410912000,
      "0C": 1413763200,
      "1C": 1442361600,
      "2C": 1458518400,
      "3C": 1473724800,
      "4C": 1490572800,
      "5C": 1505779200,
      "6C": 1522281600,
      "7C": 1537142400,
      "8C": 1553472000,
      "9C": 1568851200,
      AD: 1572220800,
      BD: 1580169600,
      CD: 1585008000,
      DD: 1600214400,
      ED: 1619395200,
      FD: 1632096000,
      LC: 1639353600,
      MC: 1647216000,
      "9B": 1652659200,
      GD: 1658275200,
      AC: 1662940800,
      NC: 1666569600,
      OC: 1670889600,
      PC: 1674432000,
      QC: 1679875200,
      RC: 1684368000,
      HD: 1690156800,
      BC: 1694995200,
      SC: 1698192000,
      TC: 1702252800,
      UC: 1705881600,
      VC: 1709596800,
      WC: 1715558400,
      XC: null,
      CC: null
    }
  },
  H: {
    A: {
      ID: 0.1
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "ID", "", "", ""],
    E: "Opera Mini",
    F: {
      ID: 1426464000
    }
  },
  I: {
    A: {
      DC: 0,
      J: 0.000065879,
      I: 0.656352,
      JD: 0,
      KD: 0,
      LD: 0,
      MD: 0.000131758,
      ZC: 0.000395274,
      ND: 0,
      OD: 0.00144934
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "JD", "KD", "LD", "DC", "J", "MD", "ZC", "ND", "OD", "I", "", "", ""],
    E: "Android Browser",
    F: {
      JD: 1256515200,
      KD: 1274313600,
      LD: 1291593600,
      DC: 1298332800,
      J: 1318896000,
      MD: 1341792000,
      ZC: 1374624000,
      ND: 1386547200,
      OD: 1401667200,
      I: 1718064000
    }
  },
  J: {
    A: {
      D: 0,
      A: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "D", "A", "", "", ""],
    E: "Blackberry Browser",
    F: {
      D: 1325376000,
      A: 1359504000
    }
  },
  K: {
    A: {
      A: 0,
      B: 0,
      C: 0,
      H: 1.2238,
      "7B": 0,
      YC: 0,
      "8B": 0
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "7B", "YC", "C", "8B", "H", "", "", ""],
    E: "Opera Mobile",
    F: {
      A: 1287100800,
      B: 1300752000,
      "7B": 1314835200,
      YC: 1318291200,
      C: 1330300800,
      "8B": 1349740800,
      H: 1709769600
    },
    D: {
      H: "webkit"
    }
  },
  L: {
    A: {
      I: 42.0636
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "I", "", "", ""],
    E: "Chrome for Android",
    F: {
      I: 1718064000
    }
  },
  M: {
    A: {
      "6B": 0.31075
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "6B", "", "", ""],
    E: "Firefox for Android",
    F: {
      "6B": 1718064000
    }
  },
  N: {
    A: {
      A: 0,
      B: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "", "", ""],
    E: "IE Mobile",
    F: {
      A: 1340150400,
      B: 1353456000
    }
  },
  O: {
    A: {
      "9B": 0.913605
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "9B", "", "", ""],
    E: "UC Browser for Android",
    F: {
      "9B": 1710115200
    },
    D: {
      "9B": "webkit"
    }
  },
  P: {
    A: {
      "0": 1.98584,
      J: 0.141071,
      v: 0.0217032,
      w: 0.0542579,
      x: 0.0651095,
      y: 0.119367,
      z: 0.227883,
      PD: 0.0108516,
      QD: 0,
      RD: 0.0325548,
      SD: 0,
      TD: 0,
      KC: 0,
      UD: 0.0108516,
      VD: 0,
      WD: 0.0108516,
      XD: 0,
      YD: 0,
      AC: 0,
      BC: 0.0217032,
      CC: 0.0108516,
      ZD: 0.0217032
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "J", "PD", "QD", "RD", "SD", "TD", "KC", "UD", "VD", "WD", "XD", "YD", "AC", "BC", "CC", "ZD", "v", "w", "x", "y", "z", "0", "", "", ""],
    E: "Samsung Internet",
    F: {
      "0": 1715126400,
      J: 1461024000,
      PD: 1481846400,
      QD: 1509408000,
      RD: 1528329600,
      SD: 1546128000,
      TD: 1554163200,
      KC: 1567900800,
      UD: 1582588800,
      VD: 1593475200,
      WD: 1605657600,
      XD: 1618531200,
      YD: 1629072000,
      AC: 1640736000,
      BC: 1651708800,
      CC: 1659657600,
      ZD: 1667260800,
      v: 1677369600,
      w: 1684454400,
      x: 1689292800,
      y: 1697587200,
      z: 1711497600
    }
  },
  Q: {
    A: {
      aD: 0.292105
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "aD", "", "", ""],
    E: "QQ Browser",
    F: {
      aD: 1710288000
    }
  },
  R: {
    A: {
      bD: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "bD", "", "", ""],
    E: "Baidu Browser",
    F: {
      bD: 1710201600
    }
  },
  S: {
    A: {
      cD: 0.08701,
      dD: 0
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "cD", "dD", "", "", ""],
    E: "KaiOS Browser",
    F: {
      cD: 1527811200,
      dD: 1631664000
    }
  }
};
export default exports;